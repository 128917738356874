<template>
  <div>
    <div class="a">
      <img class="one" src="../../../assets/img/WAP产品分类1-新巴黎_02.jpg" />
    </div>
    <div class="b">
      <img class="one" src="../../../assets/img/WAP产品分类1-新巴黎_03.jpg" />
    </div>
    <div class="c">
      <img class="one" src="../../../assets/img/WAP产品分类1-新巴黎_04.jpg" />
    </div>
     <div class="d">
      <img class="one" src="../../../assets/img/WAP产品分类1-新巴黎_05.jpg" />
    </div>
     <div class="e">
      <img class="one" src="../../../assets/img/WAP产品分类1-新巴黎_06.jpg" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
// .e{
//     height: 449px;
// }
// .d{
//     height: 606px;
// }
.c{
    height: 534px;
}
.b{
    height: 240px;
}
.a{
    height: 170px;
}
</style>